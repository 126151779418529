<template>
    <CommonLayoutsVue :isLogo="false" layoutContentStyle="margin-top: 2rem;">
        <template v-slot:child>

            <b-row class="Create-vc centered-max-width">
                <div class="Create-vc-header-container mb-3">
                    <div class="Create-vc-Heading left">{{ $gettext("Creating_visiting_card") }}</div>
                    <div class="active-label right"> 2/4</div>
                </div>
                <div class="Create-vc-ProgressBar"></div>
                <div class="active-bar w-50">
                </div>
            </b-row>
            <b-row class="centered-container centered-max-width zc-height-23">

                <div class="header-container text-center">
                    <h2 class="centered-header">{{ $gettext("Select_up_to_3_industry") }}</h2>
                </div>


                <div class="centered-content">
                    <b-form class="customized-form w-100">
                        <ValidationObserver ref="candidate_industry" tag="div">
                            <div>
                                <b-row class="p-1 px-3">
                                    <div class="col-12">
                                        <ValidationProvider rules="required|Industry" name="industry"
                                            v-slot="{ errors, classes }">
                                            <div class="custom-placeholder">
                                                {{ search ? '' : $gettext("Select_Industry") }}
                                            </div>
                                            <v-select id="user-industry" clearable multiple placeholder=""
                                                class="form-control" :class="classes" v-model="industry"
                                                @search="searchIndustry" @input="validateIndustryMin1Max3(industry)"
                                                :get-option-label="(option) => option.speciality" :options="industryOption">
                                            </v-select>
                                            <div class="error-msg" :class="classes">
                                                <span>{{ errors[0] }}</span>
                                                <!-- <span>{{ errors[0] ? 'Field Required' : errors[0] }}</span> -->
                                            </div>
                                        </ValidationProvider>
                                        <!-- <ValidationProvider name="industry" tag="div" v-slot="{ errors, classes }">
                                        <multiselect id="user-industry" class="multis" :class="classes" v-model="industry"
                                            placeholder="Select Industry" label="options" track-by="options"
                                            :options="['Administration', 'Information technology', 'Computer science', 'Advertise', 'It Industry', 'Textile']"
                                            :multiple="true" />
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? $gettext("Field_required") : errors[0] }}</span>
                                        </div>
                                    </ValidationProvider> -->
                                    </div>
                                </b-row>
                                <div class="selected-industry-skill m-2 px-3">
                                    <span v-for="(ins, i) in industry" class="vs__selected" :key="i">
                                        {{ ins.speciality }}
                                        <img style="cursor: pointer;" @click="removeIndustry(i)" class="ml-2"
                                            src="@/assets/zaajira-candidate/assets/icons/cross.svg" alt="" />
                                    </span>
                                </div>

                                <div class="col-12 mt-3 btn-flex-fd-row-jc-sb">
                                    <span @click="goBack" class="go-back">{{ $gettext("Back") }}</span>
                                    <b-button @click.prevent.stop="ValidateInput" variant="primary" size="sm">{{
                                        $gettext("Next") }}</b-button>
                                </div>

                                <!-- <div class="col-12 mb-4">
                                <b-button @click.prevent.stop="ValidateInput" class="btn full-width-btn" variant="primary"
                                    size="sm">Next</b-button>
                            </div> -->
                            </div>
                        </ValidationObserver>
                    </b-form>

                </div>
            </b-row>
        </template>
    </CommonLayoutsVue>
</template>

<script>
import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'
import Multiselect from "vue-multiselect";
import CandidateService from "@/apiServices/CandidateService";
import MasterServices from "@/apiServices/MasterServices";
export default {
    name: "IndustryDetails",
    components: { CommonLayoutsVue, Multiselect },
    data() {
        return {
            search: null,
            industry: null,
            industryOption: [],
        }
    },
    beforeMount() {
        this.PreFilledData()
    },
    methods: {
        goBack() {
            this.$router.push({ path: 'personal-details' })
        },
        validateIndustryMin1Max3(industry) {
            console.log("industry", industry);
            console.log("this.industry", this.industry);
        },
        searchIndustry(s, loading) {
            this.search = s
        },
        removeIndustry(index) {
            console.log(index);
            this.industry.splice(index, 1)
        },
        async getAllIndustry() {
            try {
                const AllIndustryRes = await MasterServices.GetAllIndustry()
                console.log("AllIndustryRes", AllIndustryRes.data.data.types);
                return AllIndustryRes.data.data.types;
            } catch (error) {

            }
        },
        async GetMasterData() {
            this.industryOption = await this.getAllIndustry()

        },
        async PreFilledData() {
            let loader = this.$loading.show();
            await this.GetMasterData()
            const userData = this.$store.getters.getLoggedUser
            console.log("userData.userId", userData.userId);
            CandidateService.GetselectedIndustry(userData.userId)
                .then((res) => {
                    console.log("GetselectedIndustry", res.data.data.types);
                    this.industry = res.data.data.types
                })
                .catch((error) => {
                    console.log("error", error);

                })
            loader.hide()

        },
        ValidateInput() {
            return this.$refs.candidate_industry.validate().then(async (result) => {
                if (result) {
                    console.log(result);
                    const speciality_id = this.industry.map((ind) => ind.speciality_id)
                    let industryString = speciality_id.join(',')

                    const payload = {
                        job_industry_ids: industryString
                    }
                    console.log('payload', payload);
                    let loader = this.$loading.show();
                    CandidateService.updateUser(payload)
                        .then((res) => {
                            // console.log(res);
                            // console.log('res', res.data.data);
                            // console.log('res', res.data.message);
                            this.$toasted.show(`${res.data.message}`, {
                                duration: 6000,
                                type: "success",
                                icon: "check",
                            });
                            const storePayload = {
                                userIndustry: speciality_id
                            }
                            // console.log('storePayload', storePayload);
                            this.$store.dispatch("onSetSelectedIndustry", storePayload);

                            this.$router.push({ name: "Skill-Details" })
                            loader.hide();
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    // this.$router.push({ path: 'skill-details' })
                }
            })
        }
    },

}
</script>

<style>
.UploadProfileImage {
    color: black;
    font-size: 14px;
    font-family: var(--font-family-sans-serif);
    font-weight: 600;
    line-height: 21px;
    word-wrap: break-word;
}

.avtar-content {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
}
</style>